import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { gearsMapping } from "../../pages/profile/Mapping/Mapping";
import { use } from "react";

const Inventory = ({ gears, openModal }) => {
  const filterGears = React.useCallback(
    gears ? gears.filter((gear) => !gear.withdrawn) : [],
    [gears]
  );

  const itemsToDisplay = filterGears;

  return (
    <>
      <Gears>
        {itemsToDisplay.length === 0 ? (
          <div
            style={{
              marginBlock: "auto",
              width: "100%",
              textAlign: "center",
              gridColumn: "1 / -1",
              color: "#fff",
              fontSize: "2rem",
              height: "auto",
            }}
          >
            Any part in inventory
          </div>
        ) : (
          itemsToDisplay.map((item, index) => {
            const robotPart =
              gearsMapping[item.id] || gearsMapping[`${item.id}.${item.stars}`];

            if (robotPart) {
              const backgroundImage =
                item.stars === 1
                  ? "url('assets/img/polybots/Gears/bg-gray.png')"
                  : item.stars === 2
                  ? "url('assets/img/polybots/Gears/bg-green.png')"
                  : "url('assets/img/polybots/Gears/bg-purple.png')";

              return (
                <div
                  key={index}
                  className="background"
                  style={{
                    marginBottom: "10px",
                    backgroundImage: backgroundImage,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <NFTImage
                    minted={item.minted}
                    src={robotPart.image}
                    onClick={() => openModal(robotPart, backgroundImage, item)}
                  />
                  {item.minted && (
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "12px",
                          width: "25px",
                          height: "25px",
                          backgroundImage:
                            item.network === "Moonbeam"
                              ? "url('assets/img/moonbeam.png')"
                              : item.network === "ICP"
                              ? "url('assets/img/ICP.png')"
                              : "url('assets/img/moonbeam.png')",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "100%",
                        }}
                      />
                      {!item.mintId && (
                        <span
                          style={{
                            color: "yellow",
                            fontSize: "1.5rem",
                            marginLeft: "5px",
                          }}
                        >
                          !
                        </span>
                      )}
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })
        )}
      </Gears>
    </>
  );
};

export const growAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const NFTImage = styled.img`
width: 100px;
height: 100px;
margin-left: ${({ minted }) => minted && "15px"};

@media (max-width: 770px) {
      width: 60px;
      height: 60px;
      margin-left: ${({ minted }) => minted && "10px"};
  }

  @media (max-width: 550px) {
      height: 100px;
      width: 100px;
      margin-left: ${({ minted }) => minted && "10px"};
  }

  @media (max-width: 450px) {
    img {
      width: 90px;
      height: 90px;
  }
`;

const Gears = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  padding: 15px;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - 14% - 10px);
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(1, 31, 47, 0.8);
  border-radius: 10px;
  border-left: 3px solid #00bfff;
  border-right: 3px solid #00bfff;
  border-top: 1px solid #00bfff;
  border-bottom: 1px solid #00bfff;
  animation: ${growAnimation} 0.1s ease-in-out;

  div {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    height: 350px;
    img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 770px) {
    div {
      width: 80px;
      height: 80px;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 5px;

    div {
      width: 120px;
      height: 120px;
      margin-inline: auto;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 500px) {
    gap: 5px;
  }

  @media (max-width: 450px) {
    padding: 10px;
    width: 100%;

    div {
      width: 110px;
      height: 110px;
      margin-inline: auto;
    }

    img {
      width: 90px;
      height: 90px;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #273444;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bfff;
    border-radius: 10px;
    border: 2px solid #273444;
  }

  scrollbar-width: thin;
  scrollbar-color: #3498db #273444;

  .background {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transition: 0.2s;
      transform: scale(1.05);
    }
  }
`;

export default Inventory;
