import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import WalletConnect from "../web3/walletconnect";
import { Overlay, CloseButton } from "../modals/mint/mintModalcss";

const Wallets = ({ profileData, bgimg, gems, walletConnected }) => {
  const [popUpEVM, setPopUpEVM] = useState(false);
  const [slicedWalled, setSlicedWallet] = useState("");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleWalletConnected = () => {
    walletConnected(true);
  };

  useEffect(() => {
    if (profileData.wallet == "none") {
    } else if (profileData.wallet) {
      const fullText = profileData.wallet;
      const shortenedText = fullText.slice(0, 5) + "..." + fullText.slice(-5);
      setSlicedWallet(shortenedText);
    }
  }, [profileData]);

  return (
    <>
      {popUpEVM && (
        <>
          <Overlay onClick={() => setPopUpEVM(false)} />
          <PopUpWallets>
            <ContentWalletsOne>
              <CloseButton onClick={() => setPopUpEVM(false)}>×</CloseButton>
              <h3>
                Connect your wallet to be able to{" "}
                <span style={{ color: "#00bfff" }}>get</span> and{" "}
                <span style={{ color: "#00bfff" }}>change</span> your{" "}
                <span style={{ color: "#00bfff" }}>NFTs</span>
              </h3>
              <img src="/assets/img/polybots/Treiners/000.png" />
              <div className="connectWallet WalletsOne">
                <WalletConnect user={profileData} theme={"dark"} />
              </div>
            </ContentWalletsOne>
            <ContentWalletsTwo>
              <CloseButton onClick={() => setPopUpEVM(false)}>×</CloseButton>
              <h4>
                Connect your wallet to be able to{" "}
                <span style={{ color: "#00bfff" }}>get</span> and{" "}
                <span style={{ color: "#00bfff" }}>change</span> your{" "}
                <span style={{ color: "#00bfff" }}>NFTs</span>
              </h4>
              <div className="connectWallet">
                <WalletConnect
                  user={profileData}
                  theme={"dark"}
                  walletConnected={handleWalletConnected}
                  linkWalletToUser={true}
                  showWalledText={true}
                  actualWallet={(wallet) => {
                  }}
                />
              </div>
            </ContentWalletsTwo>
          </PopUpWallets>
        </>
      )}
      <WalletsContainer>
        <WalletConnection onClick={() => setPopUpEVM(true)}>
          {" "}
          <img src="/assets/img/icon/MetaMask_logo.png" />{" "}
          {slicedWalled ? slicedWalled : "Connect Wallet"}
        </WalletConnection>
        <WalletConnection className="disabled">
          {" "}
          <img
            src="/assets/img/icon/ICP_logo.png"
            style={{ marginRight: "5px" }}
          />
          Internet Identity
        </WalletConnection>
        <WalletConnection className="disabled">
          {" "}
          <img
            src="/assets/img/icon/Immutable_logo.png"
            style={{ marginRight: "2px" }}
          />{" "}
          Immutable Passport
        </WalletConnection>
      </WalletsContainer>
    </>
  );
};

export const growAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const WalletsContainer = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itens: center;
  gap: 10px;
  height: calc(100% - 14% - 10px);
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(1, 31, 47);
  border-radius: 10px;
  border: 3px solid #00bfff;
  animation: ${growAnimation} 0.1s ease-in-out;
  color: white;

  .disabled {
    color: #808080;
    cursor: default;
    transition: none;

    &:hover {
      transform: scale(1);
    }
  }

  @media (max-width: 1200px) {
  }
`;

const WalletConnection = styled.div`
  background: white;
  color: black;
  width: 40%;
  height: 50px;
  margin-inline: auto;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 12px;
  &:hover {
    transform: scale(1.03);
  }

  img {
    height: 25px;
    width: 25px;
  }

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 991px) {
    width: 70%;
  }

  @media (max-width: 767px) {
    width: 98%;
  }
`;

export const PopUpWallets = styled.div`
  z-index: 903;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: URL("assets/img/bg/mint-bg.png") no-repeat center center;
  width: 650px;
  height: 650px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 55% 45%;

  @media (max-width: 991px) {
    padding: 20px;
    grid-template-columns: 1fr;
    width: 95%;
    height: 95%;
  }
`;

export const ContentWalletsOne = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  img {
    width: 95%;
    height: auto;
    animation: ${growAnimation} 0.1s ease-in-out;
  }

  .WalletsOne {
    display: none;
    animation: ${growAnimation} 0.1s ease-in-out;
  }

  h3 {
    display: none;
  }

  CloseButton {
    display: none;
  }

  @media (max-width: 991px) {
    img {
      width: 40%;
      height: auto;
    }

    .WalletsOne {
      display: block;
    }

    h3 {
      display: block;
    }

    CloseButton {
      display: block;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 60%;
      height: auto;
    }
  }
`;

export const ContentWalletsTwo = styled.div`
  height: 100%;
  width: 100%;
  background: rgb(1, 31, 47);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 0px 10px 10px 0px;
  padding: 60px 10px;

  CloseButton {
    margin-left: 50px;
  }

  .connectWallet {
    margin-block: auto;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

export default Wallets;
