import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import {
  Popup,
  CloseButton,
  PopupContent,
  Overlay,
  CardWrapper,
  CardBackground,
  CardContent,
  CardContainer,
  CardGlow,
  AttributesContainer,
  growAnimation,
} from "./mintModalcss";

import Stepper from "../../Stepper/Stepper";
import { getItemAttributesWithBonus } from "../../../pages/profile/func/AttributesCalc";
import ConfettiComponent from "../../confetti/Confetti";
import CheckIcon from "./icons/check";
import { Buffer } from "buffer";

export default function MintModal({
  gearsExibition,
  gearsData,
  bgimg,
  jwt,
  gems,
  gears,
  farmPoints,
  profileData,
  attGears,
  closeModal,
  connectWallet,
}) {
  const [serial, setSerial] = useState(null);
  const [mintStatus, setMintStatus] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isMinting, setIsMinting] = useState(false);
  const [successMint, setSuccessMint] = useState(false);
  const [successWithdraw, setSuccessWithdraw] = useState(false);
  const [hash, setHash] = useState(null);

  const [closeNetworkHandler, setCloseNetwork] = useState(null);
  const [popUpWithdraw, setPopUpWithdraw] = useState(false);
  const [popUpNetwork, setPopUpNetwork] = useState(false);
  const [confirmNetwork, setConfirmNetwork] = useState(null);
  const [congestionedBlockchain, setCongestionedBlockchain] = useState(false);
  const [networkSelected, setNetworkSelected] = useState(null);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const [sliceWallet, setSlicedWallet] = useState(null);

  const close = () => {
    closeModal(true);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const getAttributes = useCallback(
    getItemAttributesWithBonus(gearsData.stars, gearsData.partId, gearsData.id),
    console
    [getItemAttributesWithBonus, gearsData]
  );

  useEffect(() => {
    setAttributes(getAttributes);
  }, [getAttributes]);

  const getPartData = useCallback(async () => {
    try {
      setTimeout(() => {
        setStep(2);
      }, 1000);
      const [responseMintStatus, responseGears] = await Promise.all([
        fetch(
          `https://us-central1-polybots-d02df.cloudfunctions.net/getMintStatus?partId=${gearsData.partId}`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${jwt}` },
          }
        ),
        fetch(
          `https://us-central1-polybots-d02df.cloudfunctions.net/filteredPartRanking?partId=${gearsData.partId}&partSpecificId=${gearsData.id}`,
          {
            method: "GET",
          }
        ),
      ]);

      // Verifica se a resposta foi bem-sucedida
      if (!responseGears.ok) {
        throw new Error(`Erro na requisição: ${responseGears.status}`);
      }
      if (!responseMintStatus.ok) {
        throw new Error(`Erro na requisição: ${responseGears.status}`);
      }

      const [dataMintStatus, dataGears] = await Promise.all([
        responseMintStatus.json(),
        responseGears.json(),
      ]);

      setMintStatus(dataMintStatus);

      setStep(3);

      setTimeout(() => {
        setStep(4);
      }, 500);

      setTimeout(() => {
        setSerial(dataGears.rank);
      }, 1200);

      setLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a requisição:", error);
    }
  }, [setStep, setSerial, setMintStatus, setLoading]);

  useEffect(() => {
    getPartData();
  }, []);

  useEffect(() => {
    if (profileData.wallet == "none") {
    } else if (profileData.wallet) {
      const fullText = profileData.wallet;
      const shortenedText = fullText.slice(0, 5) + "..." + fullText.slice(-5);
      setSlicedWallet(shortenedText);
    }
  }, [profileData]);

  // mint

  const handleMint = async (event) => {
    event.stopPropagation();
    if (serial && mintStatus && !isMinting) {
      try {
        const selectedNetwork = await openPopUpWaitNetwork();

        if (selectedNetwork) {
          setIsMinting(true);

          const firstSerial = serial.split("/")[0];
          const gearsType = gearsExibition.name.split(" ")[1];

          const body = {
            partId: gearsData.partId, // A unique identifier for the part.
            network: selectedNetwork, // The network name.
            partDetails: {
              id: gearsData.id, // The unique ID for this part.
              type: gearsType, // The part type (e.g., "head", "body", "legs").
              attack: attributes.attack, // The attack value (numeric).
              defense: attributes.defense, // The defense value (numeric).
              health: attributes.health, // The health value (numeric).
              agility: attributes.agility, // The agility value (numeric).
              energy: attributes.energy, // The energy value (numeric).
              stars: gearsData.stars, // The star rating (numeric).
              name: gearsExibition.imgName, // The name of the part (e.g., "YUT001").
              displayName: gearsExibition.name, // (Optional) Display name of the part.
              serial: firstSerial, // (Optional) A unique serial number for this part.
            },
          };

          // const encodedBody = btoa(JSON.stringify(body)); // Encode the body as base64
          const encodedBody = Buffer.from(JSON.stringify(body)).toString(
            "base64"
          );

          let endpointUrl;
          switch (selectedNetwork) {
            case "moonbeam":
              endpointUrl =
                "https://us-central1-polybots-d02df.cloudfunctions.net/mintPartTest";
              break;
            case "icp":
              endpointUrl =
                "https://us-central1-polybots-d02df.cloudfunctions.net/mintPartICP";
              break;
            default:
              endpointUrl = "";
          }

          try {
            const responseMintStatus = await fetch(endpointUrl, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${jwt}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ data: encodedBody }), // Send the encoded body
            });

            const mintData = await responseMintStatus.json();

            if (mintData.message == "Mint success") {
              gearsData.mintId = mintData.mintId;
              profileData.wPoints -= 10;
              gearsData.minted = true;
              setHash(mintData.hash);
              setSuccessMint(true);
            }
          } catch (error) {
            setIsMinting(false);
            console.log(`Unable to mint: ${error.message} - ${error.cause}`);
          }
        }
      } catch (error) {
        setIsMinting(false);
        console.log("Mint cancelled or no network selected");
      }
    } else {
      console.log("wait verifications, mint not available");
    }
  };

  const handleView = (event) => {
    event.stopPropagation();
    window.open(`https://moonscan.io/tx/${hash}`);
  };

  // Select Network

  const openPopUpWaitNetwork = () => {
    return new Promise((resolve, reject) => {
      setPopUpNetwork(true);

      const handleNetworkSelection = (network) => {
        setPopUpNetwork(false);
        resolve(network);
      };

      const handleCloseNetwork = () => {
        setPopUpNetwork(false);
        reject(new Error("Mint cancelled or no network selected"));
      };

      setNetworkSelected(null);
      setCloseNetwork(() => handleCloseNetwork);
      setConfirmNetwork(() => handleNetworkSelection);
    });
  };

  const closeNetwork = () => {
    if (typeof closeNetworkHandler === "function") {
      closeNetworkHandler();
    }
  };

  const handleChangeNetwork = (e) => {
    const network = e.target.value;
    setNetworkSelected(network);
  };

  const handleConfirmNetwork = () => {
    if (networkSelected && typeof confirmNetwork === "function") {
      confirmNetwork(networkSelected);
    }
  };

  const handleUserWithoutWallet = () => {
    connectWallet(true);
    close();
  };

  // Withdraw

  const handleOpenWithdraw = (event) => {
    event.stopPropagation();

    if (serial && mintStatus) {
      setPopUpWithdraw(true);
    } else {
      console.log("wait verifications, withdraw not available");
    }
  };

  const closeWithdraw = () => {
    setPopUpWithdraw(false);
  };

  const handleWithdraw = useCallback(async () => {
    closeWithdraw();
    try {
      setIsWithdrawing(true);
      const withdrawResponse = await fetch(
        `https://us-central1-polybots-d02df.cloudfunctions.net/withdrawBlockchain`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mintId: gearsData.mintId,
            partId: gearsData.partId,
          }),
        }
      );
      const withdrawData = await withdrawResponse.json();

      if (withdrawData.message === "Success") {
        profileData.balanceGem -= 20;
        attGears(gearsData.partId);
        closeWithdraw();
        setSuccessWithdraw(true);
        setHash(withdrawData.hash.split("tx/").pop());
      } else {
        throw new Error("Withdraw error");
      }
    } catch (error) {
      const gearsVerification = await fetch(
        "https://us-central1-polybots-d02df.cloudfunctions.net/getInventoryParts",
        {
          method: "GET",
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );

      if (!gearsVerification.ok) {
        throw new Error("Error when fetching profile or inventory data");
      }

      const gears = await gearsVerification.json();

      if (gears.some((gear) => gear.partId === gearsData.partId)) {
        setCongestionedBlockchain(true);
      } else {
        setSuccessWithdraw(true);
      }
    }
    setIsWithdrawing(false);
  }, [setSuccessWithdraw, closeWithdraw]);

  const isChip = gearsExibition.name.includes("Chip");

  return (
    <>
      <Overlay onClick={close} />
      <ConfettiComponent active={successMint || successWithdraw} repeat={1} />

      {popUpNetwork && (
        <>
          <OverlayNetwork onClick={closeNetwork} />
          <PopUpNetwork>
            <ContentNetwork>
              <CloseButton onClick={closeNetwork}>×</CloseButton>
              <div
                style={{
                  backgroundImage: `${bgimg}`,
                  backgroundSize: "cover",
                  width: "180px",
                  height: "180px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginInline: "auto",
                }}
              >
                <img width="150" height="150" src={gearsExibition.image}></img>
              </div>
              <h5>Cost: 10FP</h5>
              <SelectContainerNetwork>
                <StyledSelectNetwork
                  onChange={handleChangeNetwork}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select Network
                  </option>
                  <option value="moonbeam">Moonbeam</option>
                  <option value="icp">ICP</option>
                  {/* Adicione outras opções conforme necessário */}
                </StyledSelectNetwork>
              </SelectContainerNetwork>
              {(networkSelected === "moonbeam" || networkSelected === "icp") &&
                (farmPoints >= 10 ? (
                  <CardContainer
                    onClick={handleConfirmNetwork}
                    style={{
                      cursor: "pointer",
                      marginInline: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <CardContent>Confirm</CardContent>
                    <CardBackground />
                    <CardGlow />
                  </CardContainer>
                ) : (
                  <>
                    <CardContainer
                      style={{
                        cursor: "not-allowed",
                        marginInline: "auto",
                        marginTop: "10px",
                        color: "#808080",
                      }}
                    >
                      <CardContent>Confirm</CardContent>
                    </CardContainer>
                    <h5>
                      Not enough{" "}
                      <span style={{ color: "#00bfff" }}>Farm Points</span>
                    </h5>
                  </>
                ))}
            </ContentNetwork>
          </PopUpNetwork>
        </>
      )}

      {popUpWithdraw && (
        <>
          <OverlayNetwork onClick={closeWithdraw} />
          <PopUpNetwork style={{ height: "550px" }}>
            <ContentNetwork>
              <CloseButton onClick={closeWithdraw}>×</CloseButton>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#00bfff",
                }}
              >
                <h2 style={{ fontSize: "30px", color: "white" }}>
                  {gearsExibition.name}
                </h2>

                {serial}
              </div>
              <div
                style={{
                  backgroundImage: `${bgimg}`,
                  backgroundSize: "cover",
                  width: "180px",
                  height: "180px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginInline: "auto",
                }}
              >
                <img width="150" height="150" src={gearsExibition.image}></img>
              </div>
              <h5>
                Cost: 20{" "}
                <img
                  src={"/assets/img/icon/gameIcons/Gem.png"}
                  style={{ width: "20px", height: "20px" }}
                />
              </h5>
              <h6>
                Destiny Wallet:{" "}
                <span className="walletAddress">{sliceWallet}</span>
              </h6>
              <h6>
                Network: <span className="walletAddress">Moonbeam</span>
              </h6>
              {gems >= 20 ? (
                <CardContainer
                  onClick={handleWithdraw}
                  style={{
                    cursor: "pointer",
                    marginInline: "auto",
                    marginTop: "10px",
                  }}
                >
                  <CardContent>Confirm</CardContent>
                  <CardBackground />
                  <CardGlow />
                </CardContainer>
              ) : (
                <>
                  <CardContainer
                    style={{
                      cursor: "not-allowed",
                      marginInline: "auto",
                      marginTop: "10px",
                      color: "#808080",
                    }}
                  >
                    <CardContent>Confirm</CardContent>
                  </CardContainer>
                  <h5>
                    Not enough <span style={{ color: "#e159c9" }}>Gems</span>
                  </h5>
                </>
              )}
            </ContentNetwork>
          </PopUpNetwork>
        </>
      )}

      {congestionedBlockchain && (
        <>
          <OverlayNetwork onClick={() => setCongestionedBlockchain(false)} />
          <PopUpNetwork>
            <ContentNetwork>
              <CloseButton onClick={() => setCongestionedBlockchain(false)}>
                ×
              </CloseButton>
              <h2
                style={{
                  textAlign: "center",
                }}
              >
                Blockchain may be congested
              </h2>
              <CardWrapper>
                <CardContainer onClick={() => setCongestionedBlockchain(false)}>
                  <CardContent>Try Again</CardContent>
                  <CardBackground />
                  <CardGlow />
                </CardContainer>
              </CardWrapper>
            </ContentNetwork>
          </PopUpNetwork>
        </>
      )}

      <Popup className={isChip ? "shortSize" : ""}>
        <CloseButton onClick={closeModal}>×</CloseButton>
        <PopupContent>
          <div>
            {serial ? (
              <div
                style={{
                  height: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#00bfff",
                }}
              >
                <h2 style={{ fontSize: "30px", color: "white" }}>
                  {gearsExibition.name}
                </h2>

                {serial}
              </div>
            ) : (
              // Loading data
              <Stepper currentStep={step} />
            )}
          </div>
          <div
            style={{
              backgroundImage: `${bgimg}`,
              backgroundSize: "cover",
              width: "180px",
              height: "180px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-1.5rem",
            }}
          >
            <img width="150" height="150" src={gearsExibition.image}></img>
          </div>
          {/* Default screen, being showned when Mint was not called */}
          {!successMint && !successWithdraw ? (
            <>
              {/* Chips doesn't hava attributes so we don't need to show */}
              {!isChip && attributes && (
                <AttributesContainer>
                  <div>
                    <h6>Attack:</h6>
                    <h6 className="blue">{attributes.attack}</h6>
                  </div>
                  <div>
                    <h6>Defense:</h6>
                    <h6 className="blue">{attributes.defense}</h6>
                  </div>
                  <div>
                    <h6>Health:</h6>
                    <h6 className="blue">{attributes.health}</h6>
                  </div>
                  <div>
                    <h6>Agility:</h6>
                    <h6 className="blue">{attributes.agility}</h6>
                  </div>
                  <div>
                    <h6>Energy:</h6>
                    <h6 className="blue">{attributes.energy}</h6>
                  </div>
                </AttributesContainer>
              )}
              {/* Default screen, when part is not minted */}
              {!gearsData.minted ? (
                <CardWrapper>
                  {serial ? (
                    <CardContainer
                      onClick={(event) => !isMinting && handleMint(event)}
                      style={{ cursor: isMinting ? "not-allowed" : "pointer" }}
                    >
                      <CardContent>
                        {isMinting ? "Minting..." : "Mint"}
                      </CardContent>
                      <CardBackground />
                      <CardGlow />
                    </CardContainer>
                  ) : (
                    <CardContainer
                      onClick={(event) => handleMint(event)}
                      style={{ cursor: "wait" }}
                    >
                      <CardContent style={{ color: "#808080" }}>
                        Mint
                      </CardContent>
                    </CardContainer>
                  )}
                </CardWrapper>
              ) : (
                // When the part is minted
                <CardWrapper>
                  {serial && gearsData.mintId ? (
                    profileData?.wallet !== "none" ? (
                      <>
                        <CardContainer
                          className={
                            gearsData.network === "ICP" ? "no-animation" : ""
                          }
                          onClick={(event) =>
                            !isWithdrawing &&
                            gearsData.network !== "ICP" &&
                            handleOpenWithdraw(event)
                          }
                          style={{
                            marginInline: "auto",
                            cursor:
                              isWithdrawing || gearsData.network === "ICP"
                                ? "wait"
                                : "pointer",
                            pointerEvents:
                              gearsData.network === "ICP" ? "none" : "auto",
                            color:
                              gearsData.network === "ICP"
                                ? "#808080"
                                : "#00bfff",
                          }}
                        >
                          <CardContent>
                            {isWithdrawing ? "Withdrawing..." : "Withdraw"}
                          </CardContent>
                          <CardBackground />
                          <CardGlow />
                        </CardContainer>
                      </>
                    ) : (
                      <>
                        <CardContainer
                          style={{
                            cursor: "not-allowed",
                            marginInline: "auto",
                          }}
                        >
                          <CardContent style={{ color: "#808080" }}>
                            Withdraw
                          </CardContent>
                        </CardContainer>
                        <p
                          onClick={handleUserWithoutWallet}
                          style={{
                            color: "#00bfff",
                            marginTop: "10px",
                            textAlign: "center",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Wallet connection required
                        </p>
                      </>
                    )
                  ) : (
                    <CardContainer style={{ cursor: "wait" }}>
                      <CardContent style={{ color: "#808080" }}>
                        Withdraw
                      </CardContent>
                    </CardContainer>
                  )}
                </CardWrapper>
              )}
            </>
          ) : (
            // When the part was minted in that moment and was a success
            <>
              <h2
                style={{
                  margin: "0 20px",
                  background: "#032054",
                  padding: "20px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                Your{" "}
                <span style={{ color: "#00bfff" }}>
                  {successMint ? "Mint" : "Withdraw"}
                </span>{" "}
                Was a Success
                <CheckIcon />
              </h2>
              <CardWrapper>
                {successMint || (successWithdraw && hash) ? (
                  <CardContainer
                    onClick={(event) => handleView(event)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardContent>View</CardContent>
                    <CardBackground />
                    <CardGlow />
                  </CardContainer>
                ) : (
                  <CardContainer style={{ cursor: "wait" }}>
                    <CardContent style={{ color: "#808080" }}>View</CardContent>
                  </CardContainer>
                )}
              </CardWrapper>
            </>
          )}
        </PopupContent>
      </Popup>
    </>
  );
}

const OverlayNetwork = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 902;
  cursor: pointer;
`;

const PopUpNetwork = styled.div`
  z-index: 903;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 425px;
  background: URL("assets/img/bg/mint-bg.png") no-repeat center center;
  border: 3px solid rgba(0, 191, 255, 0.9);
  box-shadow: rgba(0, 191, 255, 0.6) 0px 2px 8px 0px;
  padding: 20px; /* Espaçamento interno */
  border-radius: 10px; /* Bordas arredondadas */

  @media (max-width: 425px) {
    width: 90%;
  }
`;

const ContentNetwork = styled.div`
  width: 100%;
  height: 100%;
  animation: ${growAnimation} 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .walletAddress {
    color: #00bfff;
  }
`;

const SelectContainerNetwork = styled.div``;

const StyledSelectNetwork = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Borda cinza */
  font-size: 16px; /* Tamanho da fonte */
  width: 200px; /* Largura do select */
  border: none;
  background: #032054;
  color: white;
`;
