import React, { useEffect, useState } from "react";
import {
  growAnimation,
  Popup,
  CloseButton,
  PopupContent,
  AttributesContainer,
  Overlay,
  CardBackground,
  CardContainer,
  CardGlow,
  CardContent,
  CardWrapper,
} from "../mint/mintModalcss";
import CheckIcon from "../mint/icons/check";
import { useActiveWallet } from "thirdweb/react";
import { transferFrom } from "thirdweb/extensions/erc721";
import { getContract, sendTransaction } from "thirdweb";
import { createWallet } from "thirdweb/wallets";
import { myChain } from "../../../index";
import { client } from "../../../context/client";
import ConfettiComponent from "../../confetti/Confetti";

export default function DepositModal({
  gearsExibition,
  gearsData,
  bgimg,
  walletAddressNFT,
  pWallet,
  closeModal,
  attGears,
  token_hash,
  dna,
}) {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const wallet = useActiveWallet();

  const contract = getContract({
    address: "0xE5bc29Bc98faAAE718F200126Dfd341702163477",
    chain: myChain,
    client: client,
  });

  // useEffect(() => {
  //   console.log("Gears Data:", gearsData);
  //   console.log("Wallet Address NFT:", walletAddressNFT);
  //   console.log("Token Hash:", token_hash);
  //   console.log("DNA:", dna);
  //   console.log("Display Name:", gearsData.displayName);
  //   const partType = getPartType(gearsData.displayName);
  //   console.log("Part Type", partType);
  //   console.log("Jwt Token:", localStorage.getItem("jwt"));
  // }, [token_hash, dna]);

  const walletOptions = {
    wallets: [
      "io.metamask",
      "com.coinbase.wallet",
      "com.trustwallet.app",
      "app.phantom",
      "me.rainbow",
      "io.zerion.wallet",
    ],
  };

  const getPartType = (displayName) => {
    const partTypes = {
      "Wasp Chip": "1",
      "YUT-001 Head": "2",
      "YUT-001 Arm-R": "3",
      "YUT-001 Arm-L": "4",
      "YUT-001 Chest": "5",
      "YUT-001 Legs": "6",
      "Wasp Chip": "7",
      "YUT-002 Head": "8",
      "YUT-002 Arm-R": "9",
      "YUT-002 Arm-L": "10",
      "YUT-002 Chest": "11",
      "YUT-002 Legs": "12",
      "Fenix Chip": "13",
      "FNX-210 Head": "14",
      "FNX-210 Arm-R": "15",
      "FNX-210 Arm-L": "16",
      "FNX-210 Chest": "17",
      "FNX-210 Legs": "18",
      "Tanuki Chip": "19",
      "ANK-590 Head": "20",
      "ANK-590 Arm-R": "21",
      "ANK-590 Arm-L": "22",
      "ANK-590 Chest": "23",
      "ANK-590 Legs": "24",
      "Turtle Chip": "25",
      "TMN-380 Head": "26",
      "TMN-380 Arm-R": "27",
      "TMN-380 Arm-L": "28",
      "TMN-380 Chest": "29",
      "TMN-380 Legs": "30",
      "Tiger Chip": "31",
      "NTX-480 Head": "32",
      "NTX-480 Arm-R": "33",
      "NTX-480 Arm-L": "34",
      "NTX-480 Chest": "35",
      "NTX-480 Legs": "36",
      "Moth Chip": "37",
      "MTH-600 Head": "38",
      "MTH-600 Arm-R": "39",
      "MTH-600 Arm-L": "40",
      "MTH-600 Chest": "41",
      "MTH-600 Legs": "42",
      "Wolf Chip": "43",
      "WLF-720 Head": "44",
      "WLF-720 Arm-R": "45",
      "WLF-720 Arm-L": "46",
      "WLF-720 Chest": "47",
      "WLF-720 Legs": "48",
      "Octopus Chip": "49",
      "OCT-950 Head": "50",
      "OCT-950 Arm-R": "51",
      "OCT-950 Arm-L": "52",
      "OCT-950 Chest": "53",
      "OCT-950 Legs": "54",
    };
    return partTypes[displayName] || "";
  };

  const handleDeposit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // console.log("entrou");
      if (!wallet) {
        throw new Error("No wallet connected");
      }

      // Verifica se a carteira selecionada está disponível nas opções
      if (!walletOptions.wallets.includes(wallet.id)) {
        throw new Error("Wallet not supported");
      }

      // Cria a instância da carteira escolhida
      const thirdWallet = createWallet(wallet.id);

      const account = await thirdWallet.connect({
        client,
      });

      const transaction = transferFrom({
        contract: contract,
        from: walletAddressNFT,
        to: pWallet,
        tokenId: gearsData.tokenId,
        overrides: {},
      });

      // console.log(transaction);
      const result = await sendTransaction({
        account,
        transaction,
      });

      attGears(gearsData.tokenId);
      setSuccess(true);

      // Chamada da API após a transação ocorrer corretamente
      const jwtToken = localStorage.getItem("jwt");

      const partType = getPartType(gearsData.displayName);

      const apiResponse = await fetch(
        "https://us-central1-polybots-d02df.cloudfunctions.net/depositPart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({
            partID: dna,
            hash: token_hash,
            partType: partType,
          }),
        }
      );

      // console.log("API Response:", apiResponse);

      return {
        success: true,
        message: "Transaction successful",
        data: {
          transactionHash: result.transactionHash,
        },
      };
    } catch (error) {
      console.log("error");
      return {
        success: false,
        message: "Transaction setup failed",
        error: error.message,
      };
    } finally {
      setLoading(false);
    }
  };

  const isChip = gearsExibition.name.includes("Chip");

  return (
    <>
      <ConfettiComponent active={success} repeat={1} />
      <Overlay style={{ zIndex: "905" }} onClick={() => closeModal(true)} />
      <Popup style={{ zIndex: "906" }} className={isChip ? "shortSize" : ""}>
        <CloseButton onClick={() => closeModal(true)}>×</CloseButton>
        <PopupContent>
          <div>
            <div
              style={{
                height: "7rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#00bfff",
              }}
            >
              <h2 style={{ fontSize: "30px", color: "white" }}>
                {gearsExibition.name}
              </h2>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `${bgimg}`,
              backgroundSize: "cover",
              width: "180px",
              height: "180px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-1.5rem",
            }}
          >
            <img width="150" height="150" src={gearsExibition.image}></img>
          </div>
          {success ? (
            <h2
              style={{
                margin: "0 20px",
                background: "#032054",
                padding: "20px",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              Your <span style={{ color: "#00bfff" }}>Deposit</span> Was a
              Success
              <CheckIcon />
            </h2>
          ) : (
            <>
              {!isChip && (
                <AttributesContainer>
                  <div>
                    <h6>Attack:</h6>
                    <h6 className="blue">{gearsData.attack}</h6>
                  </div>
                  <div>
                    <h6>Defense:</h6>
                    <h6 className="blue">{gearsData.defense}</h6>
                  </div>
                  <div>
                    <h6>Health:</h6>
                    <h6 className="blue">{gearsData.health}</h6>
                  </div>
                  <div>
                    <h6>Agility:</h6>
                    <h6 className="blue">{gearsData.agility}</h6>
                  </div>
                  <div>
                    <h6>Energy:</h6>
                    <h6 className="blue">{gearsData.energy}</h6>
                  </div>
                </AttributesContainer>
              )}
              <CardWrapper>
                <CardContainer
                  onClick={(event) => handleDeposit(event)}
                  style={{ cursor: "pointer" }}
                >
                  <CardContent>
                    {loading ? "Depositing..." : "Deposit"}
                  </CardContent>
                  <CardBackground />
                  <CardGlow />
                </CardContainer>
              </CardWrapper>
            </>
          )}
        </PopupContent>
      </Popup>
    </>
  );
}
