import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-modal";
import HeaderTwo from "../../../components/header/HeaderTwo";
import Footer from "../../../components/footer/Footer";
import { Fade } from "react-reveal";

Modal.setAppElement("#root");

function Login() {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const referParam = queryParams.get("refer");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [googlePlayOrAppleID, setGooglePlayOrAppleID] = useState("");
  const [refer, setRefer] = useState(referParam || "Polybots");
  const [medium, setMedium] = useState("Medium");
  const [platformChoice, setPlatformChoice] = useState(0);
  const [language, setLanguage] = useState(0);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [googlePlayOrAppleIDError, setGooglePlayOrAppleIDError] =
    useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 765);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "submitalpha",
    });

    setEmailError(!email);
    setNameError(!name);
    setPasswordError(!password);
    setDateOfBirthError(!dateOfBirth);
    setGooglePlayOrAppleIDError(!googlePlayOrAppleID);

    if (
      language === 0 ||
      platformChoice === 0 ||
      !email ||
      !name ||
      !googlePlayOrAppleID
    ) {
      setModalMessage("Please fill in all fields");
      setIsModalOpen(true);
      return;
    }

    setLoading(true);
    try {
      const requestBody = {
        data: {
          email: email,
          nickname: name,
          platformId: googlePlayOrAppleID,
          refer: refer,
          medium: medium,
          platformChoice: platformChoice,
          language: language,
        },
      };

      const response = await fetch(
        "https://us-central1-polybots-d02df.cloudfunctions.net/userCreate",
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        if (response.status === 400 || response.status === 500) {
          setModalMessage("Please use another email!");
        } else {
          setModalMessage("Failed to register user.");
        }
        setIsModalOpen(true);
        return;
      }

      setSuccess(true);
      setModalMessage("Registration completed successfully!");
      setIsModalOpen(true);
    } catch (error) {
      console.error(error.message);
      setModalMessage("An unexpected error occurred.");
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://us-central1-polybots-d02df.cloudfunctions.net/refersRanking"
        );
        const data = await response.json();
        setIsLoading(false);
        setTotalUsers(data.reduce((acc, curr) => acc + curr.users, 0));
      } catch (error) {
        console.error("Erro ao obter os dados do ranking:", error);
      }
    };

    fetchData();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    if (success) {
      history.push("/");
    }
  };

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="cta-bg">
          <div className="container">
            <div
              style={{
                height: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#1c1424",
                  borderRadius: "10px",
                  minHeight: "850px",
                  minWidth: "320px",
                  height: "80%",
                  margin: "50px",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "80%",
                  }}
                >
                  <h1
                    style={{
                      marginTop: "50px",
                      fontSize: "50px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Join <span style={{ color: "#40a5f8" }}>Pre-Alpha</span>
                  </h1>
                  <p style={{ width: "100%", marginBottom: "30px" }}>
                    Craft your mechanical marvel, engage in strategic battles,
                    and dominate the competition.
                  </p>
                  <div className="newsletter-form">
                    {!isLoading && (
                      totalUsers >= 1000 ?
                        <h1
                          style={{
                            marginTop: "50px",
                            fontSize: "50px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <span style={{ color: "#40a5f8" }}>Pre-Alpha</span> Phase
                          is Now Complete!
                        </h1> :

                        <form
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "30px",
                          }}
                          onSubmit={handleSubmit}
                        >
                          <div className="newsletter-form-grp">
                            <i className="fa fa-envelope" />
                            <input
                              type="email"
                              placeholder="Enter your email..."
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                            {emailError && (
                              <span style={{ color: "red" }}>Put your Email</span>
                            )}
                          </div>
                          <div className="newsletter-form-grp">
                            <i className="fa fa-user" />
                            <input
                              type="text"
                              placeholder="Enter your name..."
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                            {nameError && (
                              <span style={{ color: "red" }}>Put your name</span>
                            )}
                          </div>
                          {/* {!isSmallScreen && (
                        <div className="newsletter-form-grp">
                          <i className="fa fa-calendar" />
                          <input
                            type="date"
                            placeholder="Enter your date of birth..."
                            value={dateOfBirth}
                            onChange={(e) => setDateOfBirth(e.target.value)}
                            required
                          />
                          {dateOfBirthError && (
                            <span style={{ color: "red" }}>
                              Put your date of birth
                            </span>
                          )}
                        </div>
                      )}
                      {isSmallScreen && (
                        <div className="newsletter-form-grp">
                          <i className="fa fa-calendar" />
                          <input
                            type="date"
                            placeholder="Enter your date of birth..."
                            value={dateOfBirth}
                            onChange={(e) => setDateOfBirth(e.target.value)}
                            required
                            style={{ width: "270px"}}
                          />
                          {dateOfBirthError && (
                            <span style={{ color: "red" }}>
                              Put your date of birth
                            </span>
                          )}
                        </div>
                      )} */}
                          {/* <div className="newsletter-form-grp">
                        <i className="fa fa-lock" />
                        <input
                          type="password"
                          placeholder="Enter your password..."
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        {passwordError && (
                          <span style={{ color: "red" }}>
                            Put your password
                          </span>
                        )}
                      </div> */}
                          <div
                            className=""
                            style={{ position: "relative", width: "100%" }}
                          >
                            <select
                              value={language}
                              onChange={(e) =>
                                setLanguage(parseInt(e.target.value))
                              }
                              style={{
                                width: "100%",
                                padding: "10px",
                                backgroundColor: "#1c1424",
                                appearance: "none",
                                color: "#fff",
                                WebkitAppearance: "none",
                                border: "1px solid #666",
                              }}
                            >
                              <option value={0}>Choose Language</option>
                              <option value={1}>Portuguese</option>
                              <option value={2}>English</option>
                            </select>
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "#666",
                              }}
                            >
                              &#9660;
                            </span>
                          </div>
                          <div
                            className=""
                            style={{ position: "relative", width: "100%" }}
                          >
                            <select
                              value={platformChoice}
                              onChange={(e) =>
                                setPlatformChoice(parseInt(e.target.value))
                              }
                              style={{
                                width: "100%",
                                padding: "10px",
                                backgroundColor: "#1c1424",
                                appearance: "none",
                                color: "#fff",
                                WebkitAppearance: "none",
                                border: "1px solid #666",
                              }}
                            >
                              <option value={0}>Choose Platform</option>
                              <option value={1}>Google Play</option>
                              <option value={2}>Apple ID</option>
                            </select>
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "#666",
                              }}
                            >
                              &#9660;
                            </span>
                          </div>
                          {platformChoice !== 0 && (
                            <div className="newsletter-form-grp">
                              <i className="fa fa-mobile" />
                              <input
                                type="text"
                                placeholder={`${platformChoice === 1
                                  ? "Email GooglePlay"
                                  : "Email AppleID"
                                  }...`}
                                value={googlePlayOrAppleID}
                                onChange={(e) =>
                                  setGooglePlayOrAppleID(e.target.value)
                                }
                                required
                              />
                            </div>
                          )}
                          <div className="newsletter-form-grp">
                            <i className="fa fa-tag" />
                            <input
                              type="text"
                              placeholder="Refer"
                              value={refer}
                              readOnly
                            />
                          </div>
                          {/* {!isModalOpen && ( */}
                          <Fade up delay={0.8}>
                            <button
                              id="submitalpha"
                              type="submit"
                              className="btn-style-two"
                              style={{
                                width: "200px",
                                zIndex: "100",
                                marginBottom: "50px",
                              }}
                              disabled={loading}
                            >
                              {loading ? "Submitting..." : "SUBMIT"}
                            </button>
                          </Fade>
                          {/* )} */}
                        </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {
        // If is ModalOpen the Wrapper and the PopUp appears
        isModalOpen && (
          // Wrapper
          <div
            style={{
              display: "block flex",
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.5)",
              zIndex: "1000",
            }}
          >
            {/* PopUp */}
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "350px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "#1c1424",
                border: "2px solid #666",
                borderRadius: "10px",
                padding: "40px",
              }}
            >
              <button
                onClick={closeModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  color: "#fff",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
              <h2>{modalMessage}</h2>
              <button
                onClick={closeModal}
                className="btn btn-style-two"
                style={{ marginTop: "20px" }}
              >
                OK
              </button>
            </div>
          </div>
        )
      }
    </>
  );
}

export default Login;
