import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { gearsMapping } from "../../pages/profile/Mapping/Mapping";
import { getContract } from "thirdweb";
import { useReadContract } from "thirdweb/react";
import { getOwnedNFTs } from "thirdweb/extensions/erc721";
import { myChain } from "../../index";
import { client } from "../../context/client";
import { use } from "react";

const NFTInventory = ({ walletAddressNFT, openDepositModal, removeNFT }) => {
  const [NFTList, setNFTList] = useState([]);
  
  const contract = getContract({
    address: "0xE5bc29Bc98faAAE718F200126Dfd341702163477",
    chain: myChain,
    client: client,
  });

  const { data: nfts, isLoading } = useReadContract(getOwnedNFTs, {
    contract,
    owner: walletAddressNFT,
  });

  useEffect(() => {
    console.log(nfts);
  }, [nfts]);

  useEffect(() => {
    if (!isLoading && nfts) {
      const extractedParts = nfts.map((nft) => {
        const attributes = nft.metadata.attributes;
        return {
          displayName: nft.metadata.name.split(" #")[0],
          stars: Number(
            attributes.find((attr) => attr.trait_type === "Stars")?.value || 1
          ),
          tokenId: nft.id.toString(),
          attack: attributes.find((attr) => attr.trait_type === "Attack")
            ?.value,
          defense: attributes.find((attr) => attr.trait_type === "Defense")
            ?.value,
          health: attributes.find((attr) => attr.trait_type === "Health")
            ?.value,
          agility: attributes.find((attr) => attr.trait_type === "Agility")
            ?.value,
          energy: attributes.find((attr) => attr.trait_type === "Energy")
            ?.value,
          token_hash: nft.tokenURI,
          dna: attributes.find((attr) => attr.trait_type === "DNA")?.value,
          image: nft.metadata.image,
        };
      });

      setNFTList(extractedParts);
    }
  }, [nfts, isLoading, walletAddressNFT]);

  useEffect(() => {
    if (removeNFT) {
      setNFTList((prevList) =>
        prevList.filter((item) => item.tokenId !== removeNFT)
      );
    }
  }, [removeNFT]);

  return (
    <>
      <Gears>
        {NFTList.length === 0 ? (
          <div
            style={{
              marginBlock: "auto",
              width: "100%",
              textAlign: "center",
              gridColumn: "1 / -1",
              color: "#fff",
              fontSize: "2rem",
              height: "auto",
            }}
          >
            Any part in inventory
          </div>
        ) : (
          NFTList.map((item, index) => {
            let robotPart = null;

            const gearEntry = Object.entries(gearsMapping).find(
              ([_, gear]) => gear.name === item.displayName
            );
            if (gearEntry) {
              robotPart = gearEntry[1];
            }

            if (robotPart) {
              const backgroundImage =
                Number(item.stars) === 1
                  ? "url('assets/img/polybots/Gears/bg-gray.png')"
                  : Number(item.stars) === 2
                  ? "url('assets/img/polybots/Gears/bg-green.png')"
                  : "url('assets/img/polybots/Gears/bg-purple.png')";

              return (
                <div
                  key={index}
                  className="background"
                  style={{
                    marginBottom: "10px",
                    backgroundImage: backgroundImage,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <NFTImage
                    minted={item.minted}
                    src={robotPart.image}
                    onClick={() =>
                      openDepositModal(
                        robotPart,
                        backgroundImage,
                        item,
                        walletAddressNFT,
                        item.token_hash,
                        item.dna
                      )
                    }
                  />
                </div>
              );
            }
            return null;
          })
        )}
      </Gears>
    </>
  );
};

export const growAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const NFTImage = styled.img`
width: 100px;
height: 100px;
margin-left: ${({ minted }) => minted && "15px"};

@media (max-width: 770px) {
      width: 60px;
      height: 60px;
      margin-left: ${({ minted }) => minted && "10px"};
  }

  @media (max-width: 550px) {
      height: 100px;
      width: 100px;
      margin-left: ${({ minted }) => minted && "10px"};
  }

  @media (max-width: 450px) {
    img {
      width: 90px;
      height: 90px;
  }
`;

const Gears = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding: 25px;
  align-items: flex-start;
  width: 90%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(1, 31, 47, 0.8);
  border-radius: 10px;
  border-left: 3px solid #00bfff;
  border-right: 3px solid #00bfff;
  border-top: 1px solid #00bfff;
  border-bottom: 1px solid #00bfff;
  animation: ${growAnimation} 0.1s ease-in-out;

  div {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    height: 350px;
    img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 770px) {
    div {
      width: 80px;
      height: 80px;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 5px;

    div {
      width: 120px;
      height: 120px;
      margin-inline: auto;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 500px) {
    gap: 5px;
  }

  @media (max-width: 450px) {
    padding: 5px;
    width: 100%;

    div {
      width: 110px;
      height: 110px;
      margin-inline: auto;
    }

    img {
      width: 90px;
      height: 90px;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #273444;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bfff;
    border-radius: 10px;
    border: 2px solid #273444;
  }

  scrollbar-width: thin;
  scrollbar-color: #3498db #273444;

  .background {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transition: 0.2s;
      transform: scale(1.05);
    }
  }
`;

export default NFTInventory;
