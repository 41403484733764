export const avatarMapping = {
  0: "assets/img/polybots/Avatars/Andy_New.png",
  1: "assets/img/polybots/Avatars/Tai_New.png",
  2: "assets/img/polybots/Avatars/Max_New.png",
  3: "assets/img/polybots/Avatars/Aria.png",
  4: "assets/img/polybots/Avatars/Sami.png",
  5: "assets/img/polybots/Avatars/Yuto.png",
  6: "assets/img/polybots/Avatars/Dra.Yumi.png",
  7: "assets/img/polybots/Avatars/Yuri.png",
  8: "assets/img/polybots/Avatars/Nicola.png",
};

export const backgroundMapping = {
  0: "assets/img/polybots/Backgrounds/red_bg.png",
  1: "assets/img/polybots/Backgrounds/Blue.png",
  2: "assets/img/polybots/Backgrounds/Green_new.png",
  3: "assets/img/polybots/Backgrounds/pink_bg.png",
  4: "assets/img/polybots/Backgrounds/violet_bg.png",
  5: "assets/img/polybots/Backgrounds/alpha_png_BG.png",
  6: "assets/img/polybots/Backgrounds/beta_bg.png",
  7: "assets/img/polybots/Backgrounds/moonbeam.png",
  8: "assets/img/polybots/Backgrounds/icp.png",
};

export const flagMapping = {
  argentina: "assets/img/polybots/Flags/argentina.png",
  australia: "assets/img/polybots/Flags/australia.png",
  brazil: "assets/img/polybots/Flags/brazil.png",
  croatia: "assets/img/polybots/Flags/croatia.png",
  default: "assets/img/polybots/Flags/defeault.png",
  england: "assets/img/polybots/Flags/england.png",
  france: "assets/img/polybots/Flags/france.png",
  germany: "assets/img/polybots/Flags/germany.png",
  iceland: "assets/img/polybots/Flags/iceland.png",
  italy: "assets/img/polybots/Flags/italy.png",
  jamaica: "assets/img/polybots/Flags/jamaica.png",
  japan: "assets/img/polybots/Flags/japan.png",
  poland: "assets/img/polybots/Flags/poland.png",
  russia: "assets/img/polybots/Flags/russia.png",
  "south-africa": "assets/img/polybots/Flags/south-africa.png",
  switzerland: "assets/img/polybots/Flags/switzerland.png",
  tunisia: "assets/img/polybots/Flags/tunisia.png",
  "united-states": "assets/img/polybots/Flags/united-states.png",
  uruguay: "assets/img/polybots/Flags/uruguay.png",
};

export const gearsMapping = {
  1.1: {
    image: "assets/img/polybots/Medals/Wasp/medal_insect bronze.png",
    name: "Wasp Chip",
    imgName: "WaspChip",
  },
  1.2: {
    image: "assets/img/polybots/Medals/Wasp/medal_insect silver.png",
    name: "Wasp Chip",
    imgName: "WaspChip",
  },
  1.3: {
    image: "assets/img/polybots/Medals/Wasp/medal_wasp_gold.png",
    name: "Wasp Chip",
    imgName: "WaspChip",
  },
  2: {
    image: "assets/img/polybots/Gears/blue-01.png",
    name: "YUT-001 Head",
    imgName: "BlueHead",
  },
  5: {
    image: "assets/img/polybots/Gears/blue-02.png",
    name: "YUT-001 Chest",
    imgName: "BlueChest",
  },
  4: {
    image: "assets/img/polybots/Gears/blue-03.png",
    name: "YUT-001 Arm-L",
    imgName: "BlueArmL",
  },
  3: {
    image: "assets/img/polybots/Gears/blue-04.png",
    name: "YUT-001 Arm-R",
    imgName: "BlueArmR",
  },
  6: {
    image: "assets/img/polybots/Gears/blue-05.png",
    name: "YUT-001 Legs",
    imgName: "BlueLegs",
  },

  7.1: {
    image: "assets/img/polybots/Medals/Wasp/medal_insect bronze.png",
    name: "Wasp Chip",
    imgName: "WaspChip",
  },
  7.2: {
    image: "assets/img/polybots/Medals/Wasp/medal_insect silver.png",
    name: "Wasp Chip",
    imgName: "WaspChip",
  },
  7.3: {
    image: "assets/img/polybots/Medals/Wasp/medal_wasp_gold.png",
    name: "Wasp Chip",
    imgName: "WaspChip",
  },
  8: {
    image: "assets/img/polybots/Gears/yellow-01.png",
    name: "YUT-002 Head",
    imgName: "YellowHead",
  },
  11: {
    image: "assets/img/polybots/Gears/yellow-02.png",
    name: "YUT-002 Chest",
    imgName: "YellowChest",
  },
  10: {
    image: "assets/img/polybots/Gears/yellow-03.png",
    name: "YUT-002 Arm-L",
    imgName: "YellowArmL",
  },
  9: {
    image: "assets/img/polybots/Gears/yellow-04.png",
    name: "YUT-002 Arm-R",
    imgName: "YellowArmR",
  },
  12: {
    image: "assets/img/polybots/Gears/yellow-05.png",
    name: "YUT-002 Legs",
    imgName: "YellowLegs",
  },

  13.1: {
    image: "assets/img/polybots/Medals/Fenix/medal_fenix bronze.png",
    name: "Fenix Chip",
    imgName: "FenixChip",
  },
  13.2: {
    image: "assets/img/polybots/Medals/Fenix/medal_fenix silver.png",
    name: "Fenix Chip",
    imgName: "FenixChip",
  },
  13.3: {
    image: "assets/img/polybots/Medals/Fenix/medal_fenix gold.png",
    name: "Fenix Chip",
    imgName: "FenixChip",
  },
  14: {
    image: "assets/img/polybots/Gears/red-01.png",
    name: "FNX-210 Head",
    imgName: "FenixHead",
  },
  17: {
    image: "assets/img/polybots/Gears/red-02.png",
    name: "FNX-210 Chest",
    imgName: "FenixChest",
  },
  16: {
    image: "assets/img/polybots/Gears/red-03.png",
    name: "FNX-210 Arm-L",
    imgName: "FenixArmL",
  },
  15: {
    image: "assets/img/polybots/Gears/red-04.png",
    name: "FNX-210 Arm-R",
    imgName: "FenixArmR",
  },
  18: {
    image: "assets/img/polybots/Gears/red-05.png",
    name: "FNX-210 Legs",
    imgName: "FenixLegs",
  },

  19.1: {
    image: "assets/img/polybots/Medals/Tanuki/medal_tanuki bronze.png",
    name: "Tanuki Chip",
    imgName: "TanukiChip",
  },
  19.2: {
    image: "assets/img/polybots/Medals/Tanuki/medal_tanuki silver.png",
    name: "Tanuki Chip",
    imgName: "TanukiChip",
  },
  19.3: {
    image: "assets/img/polybots/Medals/Tanuki/medal_tanuki gold.png",
    name: "Tanuki Chip",
    imgName: "TanukiChip",
  },
  20: {
    image: "assets/img/polybots/Gears/pink-01.png",
    name: "ANK-590 Head",
    imgName: "TanukiHead",
  },
  23: {
    image: "assets/img/polybots/Gears/pink-02.png",
    name: "ANK-590 Chest",
    imgName: "TanukiChest",
  },
  22: {
    image: "assets/img/polybots/Gears/pink-03.png",
    name: "ANK-590 Arm-L",
    imgName: "TanukiArmL",
  },
  21: {
    image: "assets/img/polybots/Gears/pink-04.png",
    name: "ANK-590 Arm-R",
    imgName: "TanukiArmR",
  },
  24: {
    image: "assets/img/polybots/Gears/pink-05.png",
    name: "ANK-590 Legs",
    imgName: "TanukiLegs",
  },

  25.1: {
    image: "assets/img/polybots/Medals/Turtle/medal_turtle bronze.png",
    name: "Turtle Chip",
    imgName: "TurtleChip",
  },
  25.2: {
    image: "assets/img/polybots/Medals/Turtle/medal_turtle silver.png",
    name: "Turtle Chip",
    imgName: "TurtleChip",
  },
  25.3: {
    image: "assets/img/polybots/Medals/Turtle/medal_turtle gold.png",
    name: "Turtle Chip",
    imgName: "TurtleChip",
  },
  26: {
    image: "assets/img/polybots/Gears/green-01.png",
    name: "TMN-380 Head",
    imgName: "TurtleHead",
  },
  29: {
    image: "assets/img/polybots/Gears/green-02.png",
    name: "TMN-380 Chest",
    imgName: "TurtleChest",
  },
  28: {
    image: "assets/img/polybots/Gears/green-03.png",
    name: "TMN-380 Arm-L",
    imgName: "TurtleArmL",
  },
  27: {
    image: "assets/img/polybots/Gears/green-04.png",
    name: "TMN-380 Arm-R",
    imgName: "TurtleArmR",
  },
  30: {
    image: "assets/img/polybots/Gears/green-05.png",
    name: "TMN-380 Legs",
    imgName: "TurtleLegs",
  },

  31.1: {
    image: "assets/img/polybots/Medals/Tiger/medal_tiger bronze.png",
    name: "Tiger Chip",
    imgName: "TigerChip",
  },
  31.2: {
    image: "assets/img/polybots/Medals/Tiger/medal_tiger silver.png",
    name: "Tiger Chip",
    imgName: "TigerChip",
  },
  31.3: {
    image: "assets/img/polybots/Medals/Tiger/medal_tiger gold.png",
    name: "Tiger Chip",
    imgName: "TigerChip",
  },
  32: {
    image: "assets/img/polybots/Gears/tiger-01.png",
    name: "NTX-480 Head",
    imgName: "TigerHead",
  },
  35: {
    image: "assets/img/polybots/Gears/tiger-02.png",
    name: "NTX-480 Chest",
    imgName: "TigerChest",
  },
  34: {
    image: "assets/img/polybots/Gears/tiger-03.png",
    name: "NTX-480 Arm-L",
    imgName: "TigerArmL",
  },
  33: {
    image: "assets/img/polybots/Gears/tiger-04.png",
    name: "NTX-480 Arm-R",
    imgName: "TigerArmR",
  },
  36: {
    image: "assets/img/polybots/Gears/tiger-05.png",
    name: "NTX-480 Legs",
    imgName: "TigerLegs",
  },

  37.1: {
    image: "assets/img/polybots/Medals/Moth/medal_vesp bronze.png",
    name: "Moth Chip",
    imgName: "MothChip",
  },
  37.2: {
    image: "assets/img/polybots/Medals/Moth/medal_vesp silver.png",
    name: "Moth Chip",
    imgName: "MothChip",
  },
  37.3: {
    image: "assets/img/polybots/Medals/Moth/medal_vesp gold.png",
    name: "Moth Chip",
    imgName: "MothChip",
  },
  38: {
    image: "assets/img/polybots/Gears/solana-01.png",
    name: "MTH-600 Head",
    imgName: "MothHead",
  },
  41: {
    image: "assets/img/polybots/Gears/solana-02.png",
    name: "MTH-600 Chest",
    imgName: "MothChest",
  },
  40: {
    image: "assets/img/polybots/Gears/solana-03.png",
    name: "MTH-600 Arm-L",
    imgName: "MothArmL",
  },
  39: {
    image: "assets/img/polybots/Gears/solana-04.png",
    name: "MTH-600 Arm-R",
    imgName: "MothArmR",
  },
  42: {
    image: "assets/img/polybots/Gears/solana-05.png",
    name: "MTH-600 Legs",
    imgName: "MothLegs",
  },
  43.1: {
    image: "assets/img/polybots/Medals/Wolf/medal_wolf bronze.png",
    name: "Wolf Chip",
    imgName: "WolfChip",
  },
  43.2: {
    image: "assets/img/polybots/Medals/Wolf/medal_wolf silver.png",
    name: "Wolf Chip",
    imgName: "WolfChip",
  },
  43.3: {
    image: "assets/img/polybots/Medals/Wolf/medal_wolf gold.png",
    name: "Wolf Chip",
    imgName: "WolfChip",
  },
  44: {
    image: "assets/img/polybots/Gears/wolf-01.png",
    name: "WLF-720 Head",
    imgName: "MoonbeamHead",
  },
  47: {
    image: "assets/img/polybots/Gears/wolf-02.png",
    name: "WLF-720 Chest",
    imgName: "MoonbeamChest",
  },
  46: {
    image: "assets/img/polybots/Gears/wolf-03.png",
    name: "WLF-720 Arm-L",
    imgName: "MoonbeamArmL",
  },
  45: {
    image: "assets/img/polybots/Gears/wolf-04.png",
    name: "WLF-720 Arm-R",
    imgName: "MoonbeamArmR",
  },
  48: {
    image: "assets/img/polybots/Gears/wolf-05.png",
    name: "WLF-720 Legs",
    imgName: "MoonbeamLegs",
  },
  49.1: {
    image: "assets/img/polybots/Medals/Oct/medal_octopus_bronze.png",
    name: "Octopus Chip",
    imgName: "OCTChip",
  },
  49.2: {
    image: "assets/img/polybots/Medals/Oct/medal_octopus_silver.png",
    name: "Octopus Chip",
    imgName: "OCTChip",
  },
  49.3: {
    image: "assets/img/polybots/Medals/Oct/medal_octopus_gold.png",
    name: "Octopus Chip",
    imgName: "OCTChip",
  },
  50: {
    image: "assets/img/polybots/Gears/oct-01.png",
    name: "OCT-950 Head",
    imgName: "OCTHead",
  },
  53: {
    image: "assets/img/polybots/Gears/oct-02.png",
    name: "OCT-950 Chest",
    imgName: "OCTChest",
  },
  52: {
    image: "assets/img/polybots/Gears/oct-03.png",
    name: "OCT-950 Arm-L",
    imgName: "OCTArmL",
  },
  51: {
    image: "assets/img/polybots/Gears/oct-04.png",
    name: "OCT-950 Arm-R",
    imgName: "OCTArmR",
  },
  54: {
    image: "assets/img/polybots/Gears/oct-05.png",
    name: "OCT-950 Legs",
    imgName: "OCTLegs",
  },
};

