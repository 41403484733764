import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import WalletConnect from "../../web3/walletconnect";
import { Overlay, CloseButton } from "../../modals/mint/mintModalcss";
import {
  PopUpWallets,
  ContentWalletsOne,
  ContentWalletsTwo,
} from "../../wallets/EVMWallets";
import Inventory from "../../Inventory/Inventory";
import NFTInventory from "../../Inventory/NFTInventory";

const TextContent = () => (
  <>
    Select a wallet to be able to{" "}
    <span style={{ color: "#00bfff" }}>deposit</span> your{" "}
    <span style={{ color: "#00bfff" }}>NFTs</span>
  </>
);

export default function Deposit({
  profileData,
  closeDeposit,
  openDepositModal,
  removeNFT,
}) {
  const [showInventory, setShowInventory] = useState(false);
  const [walletAddressNFT, setwalletAddressNFT] = useState("");

  const handleShowInventory = (wallet) => {
    if (wallet) {
      setShowInventory(true);
      setwalletAddressNFT(wallet.address);
    }
  };

  return (
    <>
      <Overlay onClick={() => closeDeposit(true)} />
      <PopUpWallets>
        <ContentWalletsOne>
          <CloseButton onClick={() => closeDeposit(true)}>×</CloseButton>
          <h3>
            <TextContent />
          </h3>
          {showInventory ? (
            <NFTInventory
              walletAddressNFT={walletAddressNFT}
              openDepositModal={openDepositModal}
              removeNFT={removeNFT}
            />
          ) : (
            <img src="/assets/img/polybots/Treiners/005.png" />
          )}
          <div className="connectWallet WalletsOne">
            <WalletConnect
              user={profileData}
              theme={"dark"}
              linkWalletToUser={false}
              showWalledText={false}
              actualWallet={(wallet) => handleShowInventory(wallet)}
            />
          </div>
        </ContentWalletsOne>
        <ContentWalletsTwo>
          <CloseButton onClick={() => closeDeposit(true)}>×</CloseButton>
          <h3>
            <TextContent />
          </h3>
          <div className="connectWallet">
            <WalletConnect
              user={profileData}
              theme={"dark"}
              actualWallet={handleShowInventory}
            />
          </div>
        </ContentWalletsTwo>
      </PopUpWallets>
    </>
  );
}
