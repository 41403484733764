import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.8;
  }
`;

const LoadingImage = styled.img`
  width: 300px;
  opacity: 0.5;
  margin-bottom: 80px;
  animation: ${blink} 1.5s infinite;

  @media (max-width: 500px) {
    width: 250px;
  }
`;

export default function Loader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const images = document.images;
    const totalImages = images.length;
    let loadedImages = 0;

    const handleImageLoad = async () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        await wait(2000);
        setLoading(false);
      }
    };

    Array.from(images).forEach((img) => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener("load", handleImageLoad);
        img.addEventListener("error", handleImageLoad); // Também conta erros
      }
    });
  }, []);

  return (
    loading && (
      <div
        className="single-slider slider-bg slider-style-two"
        style={{
          backgroundImage: "url(assets/img/bg/polybots_banner3.png)",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingImage src="/assets/img/polybotslogo.png" alt="Loading" />
      </div>
    )
  );
}
