export const items = [
  {
    itemSlot: "Chip",
    itemID: 1,
    itemName: "Wasp Chip",
    imgName: "WaspChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 2,
    itemName: "YUT-001 Head",
    imgName: "YellowHead",
    stats: {
      attack: { "1star": 15, "2star": 17, "3star": 20 },
      defense: { "1star": 30, "2star": 30, "3star": 30 },
      health: { "1star": 130, "2star": 135, "3star": 140 },
      agility: { "1star": 30, "2star": 30, "3star": 30 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 3,
    itemName: "YUT-001 R-Arm",
    imgName: "YellowArmR",
    stats: {
      attack: { "1star": 45, "2star": 47, "3star": 50 },
      defense: { "1star": 10, "2star": 12, "3star": 15 },
      health: { "1star": 20, "2star": 25, "3star": 30 },
      agility: { "1star": 20, "2star": 20, "3star": 20 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 4,
    itemName: "YUT-001 L-Arm",
    imgName: "YellowArmL",
    stats: {
      attack: { "1star": 35, "2star": 37, "3star": 40 },
      defense: { "1star": 10, "2star": 12, "3star": 15 },
      health: { "1star": 20, "2star": 25, "3star": 30 },
      agility: { "1star": 30, "2star": 30, "3star": 30 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 5,
    itemName: "YUT-001 Chest",
    imgName: "YellowChest",
    stats: {
      attack: { "1star": 25, "2star": 27, "3star": 30 },
      defense: { "1star": 50, "2star": 52, "3star": 50 },
      health: { "1star": 130, "2star": 135, "3star": 140 },
      agility: { "1star": 20, "2star": 20, "3star": 20 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 6,
    itemName: "YUT-001 Legs",
    imgName: "YellowLegs",
    stats: {
      attack: { "1star": 10, "2star": 10, "3star": 10 },
      defense: { "1star": 30, "2star": 30, "3star": 30 },
      health: { "1star": 150, "2star": 155, "3star": 180 },
      agility: { "1star": 30, "2star": 35, "3star": 40 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 19,
    itemName: "Tanuki Chip",
    imgName: "TanukiChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 20,
    itemName: "ANK-590 Head",
    imgName: "TanukiHead",
    stats: {
      attack: { "1star": 20, "2star": 25, "3star": 30 },
      defense: { "1star": 20, "2star": 22, "3star": 25 },
      health: { "1star": 80, "2star": 85, "3star": 90 },
      agility: { "1star": 20, "2star": 20, "3star": 25 },
      energy: { "1star": 15, "2star": 15, "3star": 15 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 21,
    itemName: "ANK-590 R-Arm",
    imgName: "TanukiArmR",
    stats: {
      attack: { "1star": 40, "2star": 42, "3star": 45 },
      defense: { "1star": 25, "2star": 25, "3star": 25 },
      health: { "1star": 90, "2star": 95, "3star": 100 },
      agility: { "1star": 20, "2star": 22, "3star": 25 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 22,
    itemName: "ANK-590 L-Arm",
    imgName: "TanukiArmL",
    stats: {
      attack: { "1star": 40, "2star": 42, "3star": 45 },
      defense: { "1star": 25, "2star": 25, "3star": 25 },
      health: { "1star": 90, "2star": 95, "3star": 100 },
      agility: { "1star": 20, "2star": 22, "3star": 25 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 23,
    itemName: "ANK-590 Chest",
    imgName: "TanukiChest",
    stats: {
      attack: { "1star": 25, "2star": 27, "3star": 30 },
      defense: { "1star": 30, "2star": 32, "3star": 35 },
      health: { "1star": 151, "2star": 155, "3star": 160 },
      agility: { "1star": 20, "2star": 22, "3star": 25 },
      energy: { "1star": 25, "2star": 25, "3star": 25 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 24,
    itemName: "ANK-590 Legs",
    imgName: "TanukiLegs",
    stats: {
      attack: { "1star": 20, "2star": 22, "3star": 25 },
      defense: { "1star": 25, "2star": 27, "3star": 30 },
      health: { "1star": 110, "2star": 114, "3star": 119 },
      agility: { "1star": 30, "2star": 32, "3star": 35 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 13,
    itemName: "Fenix Chip",
    imgName: "FenixChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 14,
    itemName: "FNX-210 Head",
    imgName: "FenixHead",
    stats: {
      attack: { "1star": 35, "2star": 37, "3star": 40 },
      defense: { "1star": 25, "2star": 27, "3star": 30 },
      health: { "1star": 50, "2star": 55, "3star": 60 },
      agility: { "1star": 20, "2star": 22, "3star": 25 },
      energy: { "1star": 15, "2star": 15, "3star": 15 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 15,
    itemName: "FNX-210 R-Arm",
    imgName: "FenixArmR",
    stats: {
      attack: { "1star": 65, "2star": 70, "3star": 75 },
      defense: { "1star": 25, "2star": 27, "3star": 30 },
      health: { "1star": 60, "2star": 60, "3star": 60 },
      agility: { "1star": 20, "2star": 25, "3star": 30 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 16,
    itemName: "FNX-210 L-Arm",
    imgName: "FenixArmL",
    stats: {
      attack: { "1star": 65, "2star": 70, "3star": 75 },
      defense: { "1star": 25, "2star": 27, "3star": 30 },
      health: { "1star": 60, "2star": 60, "3star": 60 },
      agility: { "1star": 20, "2star": 25, "3star": 30 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 17,
    itemName: "FNX-210 Chest",
    imgName: "FenixChest",
    stats: {
      attack: { "1star": 40, "2star": 45, "3star": 50 },
      defense: { "1star": 45, "2star": 47, "3star": 50 },
      health: { "1star": 140, "2star": 145, "3star": 150 },
      agility: { "1star": 20, "2star": 22, "3star": 24 },
      energy: { "1star": 25, "2star": 25, "3star": 25 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 18,
    itemName: "FNX-210 Legs",
    imgName: "FenixLegs",
    stats: {
      attack: { "1star": 16, "2star": 18, "3star": 20 },
      defense: { "1star": 30, "2star": 35, "3star": 40 },
      health: { "1star": 90, "2star": 95, "3star": 100 },
      agility: { "1star": 40, "2star": 40, "3star": 40 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 31,
    itemName: "Tiger Chip",
    imgName: "TigerChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 32,
    itemName: "NTX-480 Head",
    imgName: "TigerHead",
    stats: {
      attack: { "1star": 25, "2star": 25, "3star": 30 },
      defense: { "1star": 25, "2star": 25, "3star": 30 },
      health: { "1star": 50, "2star": 55, "3star": 60 },
      agility: { "1star": 36, "2star": 38, "3star": 40 },
      energy: { "1star": 15, "2star": 15, "3star": 15 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 33,
    itemName: "NTX-480 R-Arm",
    name: "TigerArmR",
    stats: {
      attack: { "1star": 50, "2star": 53, "3star": 55 },
      defense: { "1star": 25, "2star": 27, "3star": 30 },
      health: { "1star": 60, "2star": 65, "3star": 70 },
      agility: { "1star": 45, "2star": 45, "3star": 45 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 34,
    itemName: "NTX-480 L-Arm",
    name: "TigerArmL",
    stats: {
      attack: { "1star": 50, "2star": 53, "3star": 55 },
      defense: { "1star": 25, "2star": 27, "3star": 30 },
      health: { "1star": 60, "2star": 65, "3star": 70 },
      agility: { "1star": 45, "2star": 45, "3star": 45 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 35,
    itemName: "NTX-480 Chest",
    name: "TigerChest",
    stats: {
      attack: { "1star": 30, "2star": 32, "3star": 35 },
      defense: { "1star": 35, "2star": 37, "3star": 40 },
      health: { "1star": 120, "2star": 125, "3star": 130 },
      agility: { "1star": 30, "2star": 32, "3star": 35 },
      energy: { "1star": 25, "2star": 25, "3star": 25 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 36,
    itemName: "NTX-480 Legs",
    name: "TigerLegs",
    stats: {
      attack: { "1star": 20, "2star": 22, "3star": 25 },
      defense: { "1star": 30, "2star": 32, "3star": 35 },
      health: { "1star": 100, "2star": 100, "3star": 100 },
      agility: { "1star": 50, "2star": 55, "3star": 59 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 37,
    itemName: "Moth Chip",
    name: "MothChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 38,
    itemName: "MTH-600 Head",
    name: "MothHead",
    stats: {
      attack: { "1star": 20, "2star": 22, "3star": 25 },
      defense: { "1star": 45, "2star": 47, "3star": 50 },
      health: { "1star": 70, "2star": 75, "3star": 80 },
      agility: { "1star": 20, "2star": 22, "3star": 25 },
      energy: { "1star": 15, "2star": 15, "3star": 15 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 39,
    itemName: "MTH-600 R-Arm",
    name: "MothArmR",
    stats: {
      attack: { "1star": 30, "2star": 32, "3star": 35 },
      defense: { "1star": 50, "2star": 52, "3star": 55 },
      health: { "1star": 70, "2star": 75, "3star": 80 },
      agility: { "1star": 25, "2star": 25, "3star": 25 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 40,
    itemName: "MTH-600 L-Arm",
    name: "MothArmL",
    stats: {
      attack: { "1star": 30, "2star": 32, "3star": 35 },
      defense: { "1star": 50, "2star": 52, "3star": 55 },
      health: { "1star": 70, "2star": 75, "3star": 80 },
      agility: { "1star": 25, "2star": 25, "3star": 25 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 41,
    itemName: "MTH-600 Chest",
    name: "MothChest",
    stats: {
      attack: { "1star": 20, "2star": 22, "3star": 25 },
      defense: { "1star": 60, "2star": 62, "3star": 65 },
      health: { "1star": 110, "2star": 114, "3star": 119 },
      agility: { "1star": 20, "2star": 22, "3star": 25 },
      energy: { "1star": 25, "2star": 25, "3star": 25 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 42,
    itemName: "MTH-600 Legs",
    name: "MothLegs",
    stats: {
      attack: { "1star": 20, "2star": 22, "3star": 25 },
      defense: { "1star": 55, "2star": 55, "3star": 55 },
      health: { "1star": 91, "2star": 96, "3star": 100 },
      agility: { "1star": 30, "2star": 32, "3star": 35 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 43,
    itemName: "Wolf Chip",
    name: "WolfChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 44,
    itemName: "WLF-720 Head",
    name: "MoonbeamHead",
    stats: {
      attack: { "1star": 40, "2star": 40, "3star": 40 },
      defense: { "1star": 35, "2star": 40, "3star": 45 },
      health: { "1star": 50, "2star": 55, "3star": 60 },
      agility: { "1star": 30, "2star": 30, "3star": 30 },
      energy: { "1star": 15, "2star": 15, "3star": 15 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 45,
    itemName: "WLF-720 R-Arm",
    name: "MoonbeamArmR",
    stats: {
      attack: { "1star": 50, "2star": 55, "3star": 60 },
      defense: { "1star": 30, "2star": 30, "3star": 30 },
      health: { "1star": 30, "2star": 30, "3star": 30 },
      agility: { "1star": 55, "2star": 60, "3star": 65 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 46,
    itemName: "WLF-720 L-Arm",
    name: "MoonbeamArmL",
    stats: {
      attack: { "1star": 50, "2star": 55, "3star": 60 },
      defense: { "1star": 30, "2star": 30, "3star": 30 },
      health: { "1star": 30, "2star": 30, "3star": 30 },
      agility: { "1star": 55, "2star": 60, "3star": 65 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 47,
    itemName: "WLF-720 Chest",
    name: "MoonbeamChest",
    stats: {
      attack: { "1star": 40, "2star": 40, "3star": 40 },
      defense: { "1star": 30, "2star": 35, "3star": 40 },
      health: { "1star": 80, "2star": 85, "3star": 90 },
      agility: { "1star": 30, "2star": 30, "3star": 30 },
      energy: { "1star": 25, "2star": 25, "3star": 25 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 48,
    itemName: "WLF-720 Legs",
    name: "MoonbeamLegs",
    stats: {
      attack: { "1star": 40, "2star": 45, "3star": 50 },
      defense: { "1star": 35, "2star": 35, "3star": 35 },
      health: { "1star": 71, "2star": 75, "3star": 79 },
      agility: { "1star": 100, "2star": 105, "3star": 110 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 25,
    itemName: "Turtle Chip",
    name: "TurtleChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 26,
    itemName: "TMN-380 Head",
    name: "TurtleHead",
    stats: {
      attack: { "1star": 30, "2star": 32, "3star": 35 },
      defense: { "1star": 30, "2star": 32, "3star": 35 },
      health: { "1star": 60, "2star": 65, "3star": 70 },
      agility: { "1star": 25, "2star": 25, "3star": 25 },
      energy: { "1star": 15, "2star": 15, "3star": 15 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 27,
    itemName: "TMN-380 R-Arm",
    name: "TurtleArmR",
    stats: {
      attack: { "1star": 53, "2star": 56, "3star": 60 },
      defense: { "1star": 30, "2star": 32, "3star": 35 },
      health: { "1star": 70, "2star": 70, "3star": 70 },
      agility: { "1star": 25, "2star": 27, "3star": 30 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 28,
    itemName: "TMN-380 L-Arm",
    name: "TurtleArmL",
    stats: {
      attack: { "1star": 53, "2star": 56, "3star": 60 },
      defense: { "1star": 30, "2star": 32, "3star": 35 },
      health: { "1star": 70, "2star": 70, "3star": 70 },
      agility: { "1star": 25, "2star": 27, "3star": 30 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 29,
    itemName: "TMN-380 Chest",
    name: "TurtleChest",
    stats: {
      attack: { "1star": 30, "2star": 35, "3star": 40 },
      defense: { "1star": 40, "2star": 42, "3star": 45 },
      health: { "1star": 130, "2star": 135, "3star": 140 },
      agility: { "1star": 20, "2star": 25, "3star": 29 },
      energy: { "1star": 25, "2star": 25, "3star": 25 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 30,
    itemName: "TMN-380 Legs",
    name: "TurtleLegs",
    stats: {
      attack: { "1star": 20, "2star": 23, "3star": 25 },
      defense: { "1star": 35, "2star": 36, "3star": 40 },
      health: { "1star": 110, "2star": 105, "3star": 110 },
      agility: { "1star": 35, "2star": 35, "3star": 35 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 7,
    itemName: "Wasp Chip",
    name: "WaspChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 8,
    itemName: "YUT-002 Head",
    name: "BlueHead",
    stats: {
      attack: { "1star": 15, "2star": 17, "3star": 20 },
      defense: { "1star": 30, "2star": 30, "3star": 30 },
      health: { "1star": 130, "2star": 135, "3star": 140 },
      agility: { "1star": 30, "2star": 30, "3star": 30 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 9,
    itemName: "YUT-002 R-Arm",
    name: "BlueArmR",
    stats: {
      attack: { "1star": 45, "2star": 47, "3star": 50 },
      defense: { "1star": 10, "2star": 12, "3star": 15 },
      health: { "1star": 20, "2star": 25, "3star": 30 },
      agility: { "1star": 20, "2star": 20, "3star": 20 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 10,
    itemName: "YUT-002 L-Arm",
    name: "BlueArmL",
    stats: {
      attack: { "1star": 35, "2star": 37, "3star": 40 },
      defense: { "1star": 10, "2star": 12, "3star": 15 },
      health: { "1star": 20, "2star": 25, "3star": 30 },
      agility: { "1star": 30, "2star": 30, "3star": 30 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 11,
    itemName: "YUT-002 Chest",
    name: "BlueChest",
    stats: {
      attack: { "1star": 25, "2star": 27, "3star": 30 },
      defense: { "1star": 50, "2star": 50, "3star": 50 },
      health: { "1star": 130, "2star": 135, "3star": 140 },
      agility: { "1star": 20, "2star": 20, "3star": 20 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 12,
    itemName: "YUT-002 Legs",
    name: "BlueLegs",
    stats: {
      attack: { "1star": 10, "2star": 10, "3star": 10 },
      defense: { "1star": 30, "2star": 30, "3star": 30 },
      health: { "1star": 150, "2star": 155, "3star": 160 },
      agility: { "1star": 30, "2star": 35, "3star": 40 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 49.1,
    itemName: "Octopus Chip",
    imgName: "OCTChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 49.2,
    itemName: "Octopus Chip",
    imgName: "OCTChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Chip",
    itemID: 49.3,
    itemName: "Octopus Chip",
    imgName: "OCTChip",
    stats: {
      attack: { "1star": 0, "2star": 0, "3star": 0 },
      defense: { "1star": 0, "2star": 0, "3star": 0 },
      health: { "1star": 0, "2star": 0, "3star": 0 },
      agility: { "1star": 0, "2star": 0, "3star": 0 },
      energy: { "1star": 0, "2star": 0, "3star": 0 },
    },
  },
  {
    itemSlot: "Head",
    itemID: 50,
    itemName: "OCT-950 Head",
    imgName: "OCTHead",
    stats: {
      attack: { "1star": 20, "2star": 25, "3star": 30 },
      defense: { "1star": 25, "2star": 30, "3star": 35 },
      health: { "1star": 100, "2star": 110, "3star": 120 },
      agility: { "1star": 15, "2star": 20, "3star": 25 },
      energy: { "1star": 10, "2star": 15, "3star": 20 },
    },
  },
  {
    itemSlot: "Arm Right",
    itemID: 51,
    itemName: "OCT-950 Arm-R",
    imgName: "OCTArmR",
    stats: {
      attack: { "1star": 30, "2star": 30, "3star": 30 },
      defense: { "1star": 35, "2star": 35, "3star": 35 },
      health: { "1star": 160, "2star": 170, "3star": 180 },
      agility: { "1star": 20, "2star": 22, "3star": 25 },
      energy: { "1star": 15, "2star": 15, "3star": 15 },
    },
  },
  {
    itemSlot: "Arm Left",
    itemID: 52,
    itemName: "OCT-950 Arm-L",
    imgName: "OCTArmL",
    stats: {
      attack: { "1star": 25, "2star": 27, "3star": 30 },
      defense: { "1star": 30, "2star": 30, "3star": 30 },
      health: { "1star": 30, "2star": 32, "3star": 35 },
      agility: { "1star": 25, "2star": 25, "3star": 25 },
      energy: { "1star": 10, "2star": 10, "3star": 10 },
    },
  },
  {
    itemSlot: "Chest",
    itemID: 53,
    itemName: "OCT-950 Chest",
    imgName: "OCTChest",
    stats: {
      attack: { "1star": 31, "2star": 31, "3star": 31 },
      defense: { "1star": 40, "2star": 45, "3star": 50 },
      health: { "1star": 80, "2star": 85, "3star": 90 },
      agility: { "1star": 20, "2star": 22, "3star": 25 },
      energy: { "1star": 25, "2star": 25, "3star": 25 },
    },
  },
  {
    itemSlot: "Legs",
    itemID: 54,
    itemName: "OCT-950 Legs",
    imgName: "OCTLegs",
    stats: {
      attack: { "1star": 30, "2star": 30, "3star": 30 },
      defense: { "1star": 125, "2star": 135, "3star": 145 },
      health: { "1star": 80, "2star": 85, "3star": 90 },
      agility: { "1star": 40, "2star": 44, "3star": 48 },
      energy: { "1star": 20, "2star": 20, "3star": 20 },
    },
  },
];
